import React, { useState } from "react"
import { toast } from "react-toastify"

import { Button, ErrorCard, FormDialog, IconButton } from "@ioxio-priv/dataspace-ui"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import InviteFormDialog from "@/components/InviteFormDialog"
import { AuthContext } from "@/context/AuthContext"
import { Icons } from "@/dsIcon"
import DataSourceGroupsAPI from "@/services/dataSourceGroupsAPI"
import { interactionOnClickHandlers } from "@/utilities"

const defaultMember = {
  email: "",
  sub: "",
  role: "",
}

export default function GroupMembers({
  group,
  history,
  groupMembers,
  _setGroupMembers,
  _setGroupInvitations,
  _reloadGroupInvitations,
}) {
  groupMembers.sort((a, b) => {
    // Sort by role
    if (a.role !== b.role) {
      if (a.role === "pending") return -1
      if (b.role === "pending") return 1
      return a.role.localeCompare(b.role)
    } else {
      // If roles are the same, sort by email
      return a.email.localeCompare(b.email)
    }
  })
  const authContext = React.useContext(AuthContext)
  const currentUser = groupMembers.find((item) => item.sub === authContext.user.sub)
  const owners = groupMembers.filter((item) => item.role === "owner")
  const currentUserIsAnOwner = currentUser.role === "owner"
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false)
  const [memberToUpdate, setMemberToUpdate] = useState({
    isOpen: false,
    member: defaultMember,
  })
  const [memberToRemove, setMemberToRemove] = useState({
    isOpen: false,
    member: defaultMember,
  })

  const [removeError, setRemoveError] = useState()

  const isSoleOwner = owners.length === 1 && currentUserIsAnOwner
  async function _removeFromGroup(sub) {
    setRemoveError()
    const { ok, error, data } = await DataSourceGroupsAPI.removeFromGroup(group, sub)

    if (ok) {
      if (sub === authContext.user.sub) {
        history.push("/groups")
      }
      setIsLeaveDialogOpen(false)
      setMemberToRemove({
        isOpen: false,
        member: defaultMember,
      })
      _setGroupMembers(data.members)
    } else {
      if (!Array.isArray(error)) {
        toast.error(error)
      } else {
        setRemoveError(error[0].msg)
      }
    }
  }

  async function removeFromGroup(member) {
    if (member.role === "pending") {
      // cancel invitation
      await cancelInvitation(member.sub)
    } else {
      // remove member from group
      await _removeFromGroup(member.sub)
    }
  }

  async function cancelInvitation(sub) {
    const { ok, error, data } = await DataSourceGroupsAPI.cancelGroupInvitation(
      group,
      sub
    )
    if (ok) {
      setMemberToRemove({
        isOpen: false,
        member: defaultMember,
      })
      _setGroupInvitations(data.invites)
    } else {
      toast.error(error)
    }
  }

  async function updateMemberRole(member, values) {
    const { ok, error, data } = await DataSourceGroupsAPI.updateGroupMemberRole(
      group,
      member.sub,
      {
        ...values,
        role: values.role.toLowerCase(),
      }
    )
    if (ok) {
      setMemberToUpdate({
        isOpen: false,
        member: defaultMember,
      })
      _setGroupMembers(data.members)
    } else {
      toast.error(error)
    }
  }

  return (
    <Wrapper>
      <Heading
        currentUserIsAnOwner={currentUserIsAnOwner}
        setIsLeaveDialogOpen={setIsLeaveDialogOpen}
        _reloadGroupInvitations={_reloadGroupInvitations}
        _setGroupInvitations={_setGroupInvitations}
        isSoleOwner={isSoleOwner}
        group={group}
      />
      <MembersGridWrapper>
        <MembersGrid>
          <Head>
            <HeadItem>Member</HeadItem>
            <RoleHeader>Role</RoleHeader>
          </Head>
          <BodyWrapper>
            {groupMembers.map((item) => (
              <MemberItem
                key={item.email}
                currentUserIsAnOwner={currentUserIsAnOwner}
                setIsLeaveDialogOpen={setIsLeaveDialogOpen}
                isSoleOwner={isSoleOwner}
                setMemberToRemove={setMemberToRemove}
                setMemberToUpdate={setMemberToUpdate}
                {...item}
              />
            ))}
          </BodyWrapper>
        </MembersGrid>
      </MembersGridWrapper>

      <FormDialog
        isOpen={isLeaveDialogOpen}
        confirmText={"Leave"}
        submitButtonProps={{
          icon: "exit",
          color: "error",
          baseProps: {
            "data-cy": "delete-confirmation-btn",
          },
          disabled: isSoleOwner,
        }}
        onClose={() => setIsLeaveDialogOpen(false)}
        title={`Are you sure you want to leave the group?`}
        asyncOnClick={{
          asyncFn: removeFromGroup.bind(removeFromGroup, currentUser),
        }}
      >
        <>
          <p>
            This action cannot be undone, and your access to{" "}
            <GroupName>{group}</GroupName> will be removed.
          </p>
          {isSoleOwner && (
            <ErrorCard>
              You cannot leave a group that you are the sole owner of.
            </ErrorCard>
          )}
          {removeError && <ErrorCard>{removeError}</ErrorCard>}
        </>
      </FormDialog>
      {memberToRemove.isOpen && (
        <FormDialog
          isOpen={memberToRemove.isOpen}
          confirmText={"Remove"}
          submitButtonProps={{
            icon: "delete",
            iconVariant: "outlined",
            color: "error",
            baseProps: {
              "data-cy": "delete-confirmation-btn",
            },
          }}
          onClose={() =>
            setMemberToRemove({
              isOpen: false,
              member: {},
            })
          }
          title={"Are you sure you want to remove a group member?"}
          description={
            <span>
              This action can not be undone, and{" "}
              <strong>{memberToRemove.member.email}</strong>'s access to{" "}
              <strong>{group}</strong> will be removed.
            </span>
          }
          asyncOnClick={{
            asyncFn: removeFromGroup.bind(removeFromGroup, memberToRemove.member),
          }}
        />
      )}
      {memberToUpdate.isOpen && (
        <InviteFormDialog
          isOpen={memberToUpdate.isOpen}
          onClose={() => setMemberToUpdate({ isOpen: false, member: defaultMember })}
          icon={Icons.success}
          title={"Edit member role"}
          isUpdateRole={true}
          confirmText={"Save"}
          role={memberToUpdate.member.role}
          email={memberToUpdate.member.email}
          asyncOnSubmit={async (values) =>
            updateMemberRole(memberToUpdate.member, values)
          }
        />
      )}
    </Wrapper>
  )
}

function MemberItem({
  email,
  role,
  sub,
  originalRole,
  currentUserIsAnOwner,
  setIsLeaveDialogOpen,
  setMemberToUpdate,
  isSoleOwner,
  setMemberToRemove,
}) {
  const authContext = React.useContext(AuthContext)

  const isOwner = authContext.user.sub === sub
  const isPending = role === "pending"
  const hideEditIcon = isPending || (isSoleOwner && role === "owner")
  let roleToDisplay = role

  if (isOwner) {
    roleToDisplay = role + " (you)"
  }

  if (isPending) {
    roleToDisplay = originalRole + " (pending)"
  }

  return (
    <Body data-cy={`group-member-${email}`}>
      <BodyItem
        dangerouslySetInnerHTML={{
          __html: email.replace(/@/, "<wbr>@"),
        }}
      />
      <BodyItem role={role} className={"role"}>
        {roleToDisplay}
      </BodyItem>
      {currentUserIsAnOwner && (
        <BodyActionIcons>
          <EditOutlinedIcon
            sx={{ visibility: hideEditIcon ? "hidden" : "visible" }}
            aria-label="edit member role"
            role="button"
            tabIndex={0}
            data-cy={`edit-group-member-${email}`}
            className="edit-icon"
            {...interactionOnClickHandlers(() => {
              setMemberToUpdate({
                isOpen: true,
                member: {
                  email,
                  sub,
                  role,
                },
              })
            })}
          />
          <DeleteOutlinedIcon
            {...interactionOnClickHandlers(() =>
              isOwner
                ? setIsLeaveDialogOpen(true)
                : setMemberToRemove({
                    isOpen: true,
                    member: {
                      email,
                      sub,
                      role,
                    },
                  })
            )}
            aria-label="remove member"
            role="button"
            tabIndex={0}
            data-cy={`remove-group-member-${email}`}
            className="remove-icon"
            variant="outlined"
          />
        </BodyActionIcons>
      )}
    </Body>
  )
}

function Heading({
  currentUserIsAnOwner,
  isSoleOwner,
  setIsLeaveDialogOpen,
  _setGroupInvitations,
  _reloadGroupInvitations,
  group,
}) {
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false)
  const [inviteError, setInviteError] = useState()
  return (
    <Grid container justifyContent={"space-between"} alignItems={"center"}>
      <SectionTitle variant={"h3"} data-cy="group-members-title">
        Members
      </SectionTitle>
      <>
        <ButtonWrapper display={{ xs: "flex", sm: "none" }}>
          {currentUserIsAnOwner && (
            <IconButton
              color="success"
              icon={Icons.addCircle}
              iconVariant="outlined"
              onClick={() => setIsInviteDialogOpen(true)}
              baseProps={{
                "data-cy": "invite-group-member-btn",
              }}
            />
          )}
          <IconButton
            color="error"
            icon={Icons.exit}
            disabled={isSoleOwner}
            iconVariant="outlined"
            onClick={() => setIsLeaveDialogOpen(true)}
            baseProps={{
              "data-cy": "leave-group-member-btn",
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper display={{ xs: "none", sm: "flex" }}>
          {currentUserIsAnOwner && (
            <Button
              color="success"
              icon={Icons.addCircle}
              iconVariant="outlined"
              baseProps={{
                "data-cy": "invite-group-member-btn",
              }}
              onClick={() => setIsInviteDialogOpen(true)}
            >
              Invite
            </Button>
          )}
          <Button
            color="error"
            iconVariant="outlined"
            disabled={isSoleOwner}
            baseProps={{ "data-cy": `leave-group-member-btn` }}
            onClick={() => setIsLeaveDialogOpen(true)}
            icon={Icons.exit}
          >
            Leave
          </Button>
        </ButtonWrapper>
        {isInviteDialogOpen && (
          <InviteFormDialog
            isOpen={isInviteDialogOpen}
            onClose={() => {
              setInviteError()
              setIsInviteDialogOpen(false)
            }}
            icon={Icons.send}
            description={"The invite will expire in about two weeks."}
            asyncOnSubmit={async (values) => {
              setInviteError()
              const { ok, error, data, status } =
                await DataSourceGroupsAPI.inviteToGroup(group, {
                  ...values,
                  role: values.role.toLowerCase(),
                })
              if (ok) {
                _setGroupInvitations(data.invites)
                setIsInviteDialogOpen(false)
              } else {
                if (status === 422) {
                  await _reloadGroupInvitations()
                }
                if (!Array.isArray(error)) {
                  setInviteError(error)
                } else {
                  setInviteError(error[0].msg)
                }
              }
            }}
            inviteError={inviteError}
          />
        )}
      </>
    </Grid>
  )
}

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const SectionTitle = styled(Typography)`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
`
const MembersGridWrapper = styled(Box)`
  overflow-x: auto;
`
const MembersGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: fit-content;
  }
`

const Head = styled(Grid)`
  display: flex;
  height: 3.75rem;
  padding: 1rem;
  align-items: center;
  background: ${(p) => p.theme.palette.secondary.light};
  border-radius: 5px 5px 0 0;
`

const BodyWrapper = styled(Grid)`
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  padding: 0;
  margin: 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: fit-content;
  }
`

const Body = styled(Grid)`
  display: flex;
  padding: 0.5rem 1rem;
  min-height: 2.5rem;
  background: ${(p) => p.theme.palette.neutral.light};
  border-bottom: 1px solid ${(p) => p.theme.palette.neutral.main};

  &:last-of-type {
    border-bottom: none;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: fit-content;
  }
`

const HeadItem = styled("p")`
  color: ${(p) => p.theme.palette.primary.dark};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 17rem;
`
const RoleHeader = styled("p")`
  color: ${(p) => p.theme.palette.primary.dark};
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 17rem;
`
const BodyItem = styled("p")`
  color: ${(p) => findColor(p)};
  font-size: 1rem;
  font-style: normal;
  line-height: 150%;
  font-weight: 400;
  width: 17rem;
  word-break: break-word;
  padding-right: 1rem;
  &.role {
    text-transform: capitalize;
  }
`

const BodyActionIcons = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  gap: 1.5rem;

  svg {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }

  svg:focus {
    outline: 2px solid ${(p) => p.theme.palette.success.main};
    border-radius: 0.3125rem;
  }
`

const ButtonWrapper = styled(Box)`
  gap: 1rem;
`

const GroupName = styled("span")`
  font-weight: 600;
  color: ${(p) => p.theme.palette.primary.dark};
`

function findColor(p) {
  if (p.role === "owner") {
    return p.theme.palette.success.main
  } else if (p.role === "pending") {
    return p.theme.palette.text.disabled
  } else {
    return p.theme.palette.primary.dark
  }
}
