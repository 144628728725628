import React from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import { IconLink, Link, LinkButton, Submit } from "@ioxio-priv/dataspace-ui"
import { useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

import { FormBody, HorizontalLine } from "@/commonStyles"
import { SCHEMA } from "@/components/CreateDataSourceForm/validation"
import Form from "@/components/Form"
import FormCheckbox from "@/components/FormCheckbox"
import FormInput from "@/components/FormInput"
import FormSelect from "@/components/FormSelect"
import { labels } from "@/constants/labels"
import InitialLoading from "@/containers/InitialLoading"
import { Icons } from "@/dsIcon"
import useDefinitions from "@/hooks/useDefinitions"
import useMyGroups from "@/hooks/useMyGroups"
import { config } from "@/settings"
import { toastError } from "@/utilities/errors"

import "react-toastify/dist/ReactToastify.css"

export default function CreateDataSourceForm({
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { myGroups, loading: loadingMyGroups } = useMyGroups()
  const { definitions, loading: loadingDefinitions } = useDefinitions()
  const definitionOptions = Object.keys(definitions)

  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      published: false,
    },
  })

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create data source", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (!isFormValid) {
      return {
        ok: false,
      }
    }

    const data = form.getValues()
    const payload = {
      source: data.variant ? `${data.group}:${data.variant}` : data.group,
      sourceUrl: data.baseUrl,
      published: data.published,
      definition: data.definition,
    }
    return asyncOnSubmit(payload, handleBackendErrors)
  }

  if (loadingMyGroups || loadingDefinitions) {
    return <InitialLoading />
  }

  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        rightButtons={
          <>
            {smallScreen ? (
              <>
                <IconLink href="/sources" variant={"outlined"} icon={Icons.cancel} />
                <Submit
                  icon={Icons.success}
                  iconOnly
                  label={"Create"}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                />
              </>
            ) : (
              <>
                <LinkButton variant={"outlined"} icon={Icons.cancel} href={`/sources`}>
                  Cancel
                </LinkButton>
                <Submit
                  icon={Icons.success}
                  color="success"
                  asyncOnClick={{
                    asyncFn: _asyncOnSubmit,
                  }}
                >
                  Create
                </Submit>
              </>
            )}
          </>
        }
      >
        <FormBody>
          <FormSelect
            baseProps={{
              "data-cy": "definition",
            }}
            label={labels.dataSource.fields.dataProduct.label}
            name={"definition"}
            options={definitionOptions}
            form={form}
            required
            placeholder={"Data Product"}
            tooltipText={labels.dataSource.fields.dataProduct.tooltipText}
          >
            <>
              Which data product is your source implementing? View definitions or
              propose new ones at{" "}
              <Link target="_blank" rel="noreferrer" href={config.sourcesRepo}>
                {config.sourcesRepo}
              </Link>
            </>
          </FormSelect>
          <FormSelect
            baseProps={{
              "data-cy": "group",
            }}
            showAddGroupLink
            form={form}
            name={"group"}
            label={labels.dataSource.fields.group.label}
            options={myGroups.map((item) => item.group)}
            placeholder={"Select group"}
            required={true}
            tooltipText={labels.dataSource.fields.group.tooltipText}
          />
        </FormBody>
        <HorizontalLine />
        <FormBody>
          <FormInput
            baseProps={{
              autoCapitalize: "none",
              "data-cy": "variant",
            }}
            label={"Variant"}
            name={"variant"}
            form={form}
            placeholder={labels.dataSource.fields.variant.label}
            tooltipText={labels.dataSource.fields.variant.tooltipText}
          />
          <FormInput
            required
            baseProps={{
              autoCapitalize: "none",
              "data-cy": "baseUrl",
            }}
            label={labels.dataSource.fields.baseUrl.label}
            name={"baseUrl"}
            form={form}
            placeholder={"https://"}
            tooltipText={labels.dataSource.fields.baseUrl.tooltipText}
          />
          <FormCheckbox
            baseProps={{
              "data-cy": "published",
            }}
            label={labels.dataSource.fields.published.label}
            name={"published"}
            form={form}
            tooltipText={labels.dataSource.fields.published.tooltipText}
          />
        </FormBody>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
