import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import {
  Button,
  IconButton,
  IconLink,
  InputField,
  LinkButton,
  styled,
  Submit,
} from "@ioxio-priv/dataspace-ui"
import { Grid, useMediaQuery, useTheme } from "@mui/material"

import { getFormData, makeSchema } from "./validation"
import { FormBody, FormBox, HorizontalLine } from "../../commonStyles"
import Form from "../../components/Form"
import { labels } from "../../constants/labels"
import routes from "../../constants/routes"
import { Icons } from "../../dsIcon"
import { copyToClipboard, makeRange } from "../../utilities"
import { toastError } from "../../utilities/errors"
import ClientSecret from "../ClientSecret"
import RemoveFormModal from "../RemoveForm"

function FormInput({
  name,
  label,
  tooltipText,
  required,
  index = 0,
  form,
  uriId = undefined,
  onRemoveClick = undefined,
  readonly = false,
  inputSx = {},
}) {
  let err = ""
  if (Object.prototype.hasOwnProperty.call(form.formState.errors, name)) {
    err = form.formState.errors[name].message
  }

  const inputElement = (
    <Controller
      name={name}
      control={form.control}
      defaultValue={""}
      render={({ field }) => (
        <InputField
          label={index === 0 ? label : ""}
          name={name}
          required={required}
          handleChange={field.onChange}
          error={err}
          tooltipText={index === 0 ? tooltipText : ""}
          readonly={readonly}
          baseProps={{ ...field, "aria-label": label }}
        />
      )}
    />
  )
  if (!readonly && onRemoveClick) {
    return (
      <Controller
        name={name}
        control={form.control}
        defaultValue={""}
        render={({ field }) => (
          <InputField
            label={index === 0 ? label : ""}
            name={name}
            required={required}
            handleChange={field.onChange}
            error={err}
            tooltipText={index === 0 ? tooltipText : ""}
            readonly={readonly}
            baseProps={{ ...field, "aria-label": label }}
          >
            <IconButton
              color={"error"}
              icon={Icons.delete}
              iconVariant={"outlined"}
              onClick={() => onRemoveClick(uriId)}
            />
          </InputField>
        )}
      />
    )
  }
  return inputElement
}

function defaultFormValues(app, redirectUriIds, logoutRedirectUriIds) {
  const values = {
    name: app.name,
    appUrl: app.appUrl,
    privacyPolicy: app.privacyPolicy,
    group: app.group,
  }
  for (let i = 0; i < app.redirectUris.length; i++) {
    if (redirectUriIds && redirectUriIds.indexOf(i) !== -1) {
      values[`redirectUri${i}`] = app.redirectUris[i]
    }
  }
  for (let i = 0; i < app.logoutRedirectUris.length; i++) {
    if (logoutRedirectUriIds && logoutRedirectUriIds.indexOf(i) !== -1) {
      values[`logoutRedirectUri${i}`] = app.logoutRedirectUris[i]
    }
  }
  return values
}

/* --- Component itself --- */

export default function EditAppForm({
  app = {},
  asyncOnSubmit = async () => ({ ok: true }),
  onSecretRefresh = async () => ({ ok: true }),
  onAppRemove = async () => ({ ok: true }),
  csx = {},
}) {
  // UI state
  const [isRemoveFormShown, setRemoveFormShown] = useState(false)

  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  // Redirect URIs and form state
  const [redirectUriIds, setRedirectUriIds] = useState(
    makeRange(app.redirectUris.length)
  )
  const [logoutRedirectUriIds, setLogoutRedirectUriIds] = useState(
    makeRange(app.logoutRedirectUris.length)
  )
  const schema = makeSchema(redirectUriIds, logoutRedirectUriIds)
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(schema),
    defaultValues: defaultFormValues(app, redirectUriIds, logoutRedirectUriIds),
  })

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to update the application", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field, i] = loc || []
      let formFieldName = field
      if (field === "redirectUris") {
        formFieldName = `redirectUri${i}`
      } else if (field === "logoutRedirectUris") {
        formFieldName = `logoutRedirectUri${i}`
      }
      form.setError(formFieldName, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (isFormValid) {
      const data = getFormData(form, redirectUriIds, logoutRedirectUriIds)
      return asyncOnSubmit(data, handleBackendErrors)
    }
    return {
      ok: false,
    }
  }

  function addRedirectUri() {
    const maxId = Math.max.apply(null, redirectUriIds)
    setRedirectUriIds([...redirectUriIds, maxId + 1])
  }

  function removeRedirectUri(idToRemove) {
    setRedirectUriIds(redirectUriIds.filter((id) => id !== idToRemove))
    form.unregister(`redirectUri${idToRemove}`)
  }

  function addLogoutRedirectUri() {
    const maxId = Math.max.apply(null, logoutRedirectUriIds)
    setLogoutRedirectUriIds([...logoutRedirectUriIds, maxId + 1])
  }

  function removeLogoutRedirectUri(idToRemove) {
    setLogoutRedirectUriIds(logoutRedirectUriIds.filter((id) => id !== idToRemove))
    form.unregister(`logoutRedirectUri${idToRemove}`)
  }

  const FormContainer = (
    <FormBox>
      <FormBody>
        <FormInput
          name={"name"}
          label={labels.application.fields.name.label}
          required={true}
          form={form}
          tooltipText={labels.application.fields.name.tooltipText}
        />
        <FormInput
          name={"group"}
          label={labels.application.fields.group.label}
          required={true}
          form={form}
          readonly
          tooltipText={labels.application.fields.group.tooltipText}
        />
      </FormBody>
      <HorizontalLine />
      <FormBody>
        <InputField
          required={true}
          name={"clientId"}
          label={labels.application.fields.clientID.label}
          tooltipText={labels.application.fields.clientID.tooltipText}
          value={app.clientId}
          baseProps={{ readonly: true }}
        >
          <IconButton
            icon={Icons.copy}
            title={"Copy client ID"}
            color={"secondary"}
            variant={"outlined"}
            onClick={() =>
              copyToClipboard(
                app.clientId,
                "Client ID has been copied to the clipboard"
              )
            }
          />
        </InputField>

        {app.clientSecrets.map((secret, index) => (
          <ClientSecret
            required={true}
            key={secret}
            index={index}
            name={`Client secrets`}
            tooltipText={labels.application.fields.clientSecrets.tooltipText}
            secret={secret}
            app={app}
            asyncOnRefresh={onSecretRefresh}
          />
        ))}
      </FormBody>
      <HorizontalLine />

      <FormBody>
        <RedirectURIGrid>
          {redirectUriIds.map((id, index) => (
            <FormInput
              required={true}
              key={id}
              index={index}
              name={`redirectUri${id}`}
              label={labels.application.fields.redirectURIs.label}
              tooltipText={labels.application.fields.redirectURIs.tooltipText}
              form={form}
              onRemoveClick={
                redirectUriIds.length > 1 || index > 0 ? removeRedirectUri : null
              }
              uriId={id}
            />
          ))}
          <Button
            color={"success"}
            variant={"iconText"}
            icon={Icons.add}
            onClick={addRedirectUri}
            label={"Add"}
          />
        </RedirectURIGrid>
        <RedirectURIGrid>
          {logoutRedirectUriIds.map((id, index) => (
            <FormInput
              required={true}
              key={id}
              index={index}
              name={`logoutRedirectUri${id}`}
              label={labels.application.fields.logoutRedirectURIs.label}
              tooltipText={labels.application.fields.logoutRedirectURIs.tooltipText}
              form={form}
              onRemoveClick={
                logoutRedirectUriIds.length > 1 || index > 0
                  ? removeLogoutRedirectUri
                  : null
              }
              uriId={id}
            />
          ))}
          <Button
            color={"success"}
            variant={"iconText"}
            icon={Icons.add}
            onClick={addLogoutRedirectUri}
            label={"Add"}
          />
        </RedirectURIGrid>
      </FormBody>
      <HorizontalLine />
      <FormBody>
        <FormInput
          name={"appUrl"}
          label={labels.application.fields.website.label}
          tooltipText={labels.application.fields.website.tooltipText}
          form={form}
        />

        <FormInput
          name={"privacyPolicy"}
          label={labels.application.fields.privacyPolicy.label}
          form={form}
          tooltipText={labels.application.fields.privacyPolicy.tooltipText}
        />
      </FormBody>

      <RemoveFormModal
        isOpen={isRemoveFormShown}
        setIsOpen={setRemoveFormShown}
        name={app.name}
        type="application"
        asyncOnSubmit={onAppRemove}
        title={`Delete application ${app.name}?`}
      />
    </FormBox>
  )

  return (
    <Form
      onSubmit={onSubmit}
      leftButtons={
        mobileScreen ? (
          <IconButton
            color={"error"}
            onClick={() => setRemoveFormShown(true)}
            icon={Icons.delete}
            iconVariant="outlined"
          />
        ) : (
          <Button
            icon={Icons.delete}
            color={"error"}
            iconVariant={"outlined"}
            onClick={() => setRemoveFormShown(true)}
          >
            Delete
          </Button>
        )
      }
      rightButtons={
        mobileScreen ? (
          <>
            <IconLink
              icon={Icons.cancel}
              href={routes.APPLICATIONS}
              variant={"outlined"}
            />
            <Submit
              icon={Icons.success}
              label={"Save"}
              iconOnly
              color="success"
              asyncOnClick={{
                asyncFn: _asyncOnSubmit,
              }}
            />
          </>
        ) : (
          <>
            <LinkButton
              variant={"outlined"}
              icon={Icons.cancel}
              href={routes.APPLICATIONS}
            >
              Cancel
            </LinkButton>
            <Submit
              icon={Icons.success}
              color={"success"}
              asyncOnClick={{ asyncFn: _asyncOnSubmit }}
            >
              Save
            </Submit>
          </>
        )
      }
    >
      {FormContainer}
    </Form>
  )
}

const RedirectURIGrid = styled(Grid)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  gap: 1rem;
`
