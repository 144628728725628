import { Icon } from "@ioxio-priv/dataspace-ui"

import routes from "@/constants/routes.json"

export const BreadCrumbsNames = {
  DEVELOPER_PORTAL: "Developer portal",
  APPLICATIONS: "My applications",
  GROUPS: "My groups",
  EDIT_GROUPS: "Edit a group",
  SOURCES: "My data sources",
  CREATE_SOURCES: "Create a data source",
  CREATE_APPLICATION: "Create an application",
  AVAILABLE_SOURCES: "Available data sources",
  CREATE_GROUPS: "Create a group",
  EDIT_SOURCES: "Edit a data source",
  LOGIN: "Login",
  TERMS_OF_SERVICE: "Terms of Service",
  RESOURCES: "Developer resources",
}

export const BreadCrumbsPath = {
  DEVELOPER_PORTAL: { children: <Icon name={"home"} />, href: routes.HOME },
  AVAILABLE_SOURCES: {
    label: BreadCrumbsNames.AVAILABLE_SOURCES,
    href: routes.SOURCES_AVAILABLE,
  },
  APPLICATIONS: { label: BreadCrumbsNames.APPLICATIONS, href: routes.APPLICATIONS },
  GROUPS: { label: BreadCrumbsNames.GROUPS, href: routes.GROUP_LIST },
  EDIT_GROUPS: { label: BreadCrumbsNames.EDIT_GROUPS, href: routes.GROUP_EDIT_EDIT },
  SOURCES: { label: BreadCrumbsNames.SOURCES, href: routes.MY_SOURCES },
  CREATE_SOURCES: {
    label: BreadCrumbsNames.CREATE_SOURCES,
    href: routes.SOURCE_CREATE,
  },
  RESOURCES: { label: BreadCrumbsNames.RESOURCES, href: routes.RESOURCES },
  CREATE_GROUPS: { label: BreadCrumbsNames.CREATE_GROUPS, href: routes.GROUP_CREATE },
  EDIT_SOURCES: { label: BreadCrumbsNames.EDIT_SOURCES, href: routes.SOURCE_EDIT },
  LOGIN: { label: BreadCrumbsNames.LOGIN, href: routes.LOGIN },
}
