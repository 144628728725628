import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { IconLink, InfoCard, LinkButton, styled } from "@ioxio-priv/dataspace-ui"
import { Typography, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"

import { labels } from "@/constants/labels"
import ROUTES from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import SourcesWrapper from "@/containers/SourcesWrapper"
import { Icons } from "@/dsIcon"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import useToast from "@/hooks/useToast"
import DataSourceAPI from "@/services/dataSourceAPI"

import "react-toastify/dist/ReactToastify.css"

const addSourceButtonSx = {
  mb: "1.5rem",
}

function DataSourceListing({ listing }) {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Box mt={"1.5rem"}>
      {listing.map(({ definition, source }) => (
        <PrivateDataSourceGrid
          key={definition + source}
          data-testid={`${definition}-${source}`}
          container
          item
        >
          <Grid item container xs={8} sm={9}>
            <Wrapper>
              <Typography data-cy={definition} variant="h3">
                {definition}
              </Typography>
              <Typography data-cy={source} variant="subtitle1">
                {source}
              </Typography>
            </Wrapper>
          </Grid>
          {smallScreen ? (
            <IconLink
              baseProps={{
                "data-cy": `edit-${source}`,
              }}
              href={`/sources/${definition}/${source}`}
              icon={Icons.edit}
            />
          ) : (
            <LinkButton
              baseProps={{
                "data-cy": `edit-${source}`,
              }}
              icon={Icons.edit}
              iconVariant="outlined"
              key={"edit"}
              href={`/sources/${definition}/${source}`}
            >
              Edit
            </LinkButton>
          )}
        </PrivateDataSourceGrid>
      ))}
      {listing.length === 0 && <InfoCard>You have no data sources</InfoCard>}
    </Box>
  )
}

export default function MySources({ location, history }) {
  const { LOGIN, SOURCE_CREATE } = ROUTES
  const [sources, setSources] = useState([])
  const [loading, setLoading] = useState(true)

  // render navbar and footer
  useLayoutOpts(true, true)

  // fetch data sources
  useEffect(() => {
    ;(async () => {
      const { ok, data, error, status } = await DataSourceAPI.getMySources()
      if (ok) {
        setSources(data.dataSources)
        setLoading(false)
      } else {
        if (status === 403) {
          history.push({
            pathname: LOGIN,
            state: { error: error },
          })
        }
        toast.error(error)
      }
    })()
  }, [history])

  // Initialize toasts and render any in the state
  useToast(location.state)

  //sorting data sources
  sources.sort((sourceA, sourceB) =>
    sourceA.definition.localeCompare(sourceB.definition)
  )

  return (
    <SourcesWrapper
      meta={labels.meta.mySources}
      title={"My data sources"}
      belowHeader={
        <div>
          <LinkButton
            baseProps={{ sx: addSourceButtonSx, "data-cy": "add-sources" }}
            icon={Icons.add}
            href={SOURCE_CREATE}
            color={"success"}
            variant={"iconText"}
          >
            Add a data source
          </LinkButton>
        </div>
      }
    >
      <hr style={{ borderTop: 0 }} />
      {loading ? <InitialLoading /> : <DataSourceListing listing={sources} />}
    </SourcesWrapper>
  )
}

const PrivateDataSourceGrid = styled(Grid)`
  margin: 1.5rem 0;
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 0.3125rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: ${(p) => p.theme.palette.secondary.light};
  padding: 1rem;
`

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
