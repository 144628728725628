import React from "react"
import { ToastContainer } from "react-toastify"

import {
  Layout as DataspaceUILayout,
  generateNavigationFromPrismic,
  useDsConfig,
} from "@ioxio-priv/dataspace-ui"

import ROUTES from "@/constants/routes"
import { AuthContext } from "@/context/AuthContext"
import { config, dsConfig } from "@/settings"

import PRISMIC_MENU from "../menuItems.json"

export default function Layout({ children }) {
  const { menuCategories, menuItems } = PRISMIC_MENU
  dsConfig.navigationTree = generateNavigationFromPrismic(menuItems, menuCategories)
  useDsConfig(dsConfig)

  const authContext = React.useContext(AuthContext)

  const sideBarMenuItems = []
  sideBarMenuItems.push(
    {
      name: "Available data sources",
      url: ROUTES.SOURCES_AVAILABLE,
    },
    {
      name: "Developer resources",
      url: ROUTES.RESOURCES,
    },
    {
      name: "My data sources",
      url: ROUTES.MY_SOURCES,
    },
    {
      name: "My groups",
      url: ROUTES.GROUP_LIST,
    }
  )
  if (!config.disableApps) {
    sideBarMenuItems.push({
      name: "My applications",
      url: ROUTES.APPLICATIONS,
    })
  }
  sideBarMenuItems.sort((a, b) => a.name.localeCompare(b.name))
  if (!authContext.user) {
    sideBarMenuItems.push({
      name: "Login",
      url: ROUTES.LOGIN,
    })
  }

  return (
    <DataspaceUILayout
      component={"Developer Portal"}
      user={authContext.user || undefined}
      logoutPath={ROUTES.START_LOGOUT}
      componentLinks={sideBarMenuItems}
    >
      <ToastContainer />
      {children}
    </DataspaceUILayout>
  )
}
