import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { styled } from "@ioxio-priv/dataspace-ui"
import { Breadcrumbs, Button, Select } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import { MainContentBox } from "@/commonStyles"
import AvailableDataSource from "@/components/AvailableDataSource"
import Header from "@/components/Header"
import { InlineLink } from "@/components/InlineLink"
import { MetaData } from "@/components/MetaData"
import ResourcesBox from "@/components/ResourcesBox"
import Text from "@/components/Text"
import { BreadCrumbsNames, BreadCrumbsPath } from "@/constants/breadcrumbs"
import { labels } from "@/constants/labels"
import routes from "@/constants/routes"
import InitialLoading from "@/containers/InitialLoading"
import { Icons } from "@/dsIcon"
import useDefinitions from "@/hooks/useDefinitions"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import DataSourceAPI from "@/services/dataSourceAPI"
import { config, dsConfig } from "@/settings"
import { getQueryParams } from "@/utilities"
import {
  apiDocsUrl,
  definitionsViewerUrl,
  githubOpenAPISpecUrl,
  swaggerUIUrl,
} from "@/utilities/urls"

function generateSourcesText(length) {
  if (length === 0) {
    return "No published data sources"
  }
  if (length === 1) {
    return "1 data source"
  }
  return `${length} data sources`
}

function SearchResult({ data, selectedDefinition }) {
  const sourcesText = generateSourcesText(data.length)
  const [switches, updateSwitches] = useState([])
  let fullDefinitionPath = selectedDefinition?.definition
  if (selectedDefinition?.version) {
    fullDefinitionPath += `_v${selectedDefinition.version}`
  }
  const specUrl = githubOpenAPISpecUrl(fullDefinitionPath)
  const definitionsUrl = definitionsViewerUrl(fullDefinitionPath)
  const swaggerUrl = swaggerUIUrl(fullDefinitionPath)
  const docsUrl = apiDocsUrl(fullDefinitionPath)

  const additionalResources = [
    {
      text: "OpenAPI Spec source",
      link: specUrl,
    },
    {
      text: "Open in Definitions Viewer",
      link: definitionsUrl,
    },
    {
      text: "API Docs",
      link: docsUrl,
    },
    {
      text: "View in Swagger UI",
      link: swaggerUrl,
    },
  ]

  useEffect(() => {
    updateSwitches(
      data.map((item, index) => ({
        key: index,
        isOpen: false,
      }))
    )
  }, [data])

  function availableAdditionalResources() {
    return additionalResources.filter((resource) => resource.link)
  }

  function switchAll(on) {
    const updatedSwitches = switches.map((item) => {
      return { ...item, isOpen: on }
    })
    updateSwitches(updatedSwitches)
  }

  function updateSwitch(index) {
    const copyOfSwitches = [...switches]
    copyOfSwitches[index] = { key: index, isOpen: !copyOfSwitches[index].isOpen }
    updateSwitches(copyOfSwitches)
  }

  const allOpen = switches.map((item) => item.isOpen).every(Boolean)

  return (
    <>
      {selectedDefinition && (
        <>
          <Typography variant="h3">{selectedDefinition?.description}</Typography>
          <DataSourcesText baseProps={{ "data-cy": "search-info" }}>
            {`${sourcesText} found matching definition ${fullDefinitionPath}`}
          </DataSourcesText>
          <hr style={{ borderTop: 0, marginBottom: "1rem" }} />
          <ResourcesBox
            title="Additional Resources"
            links={availableAdditionalResources()}
            marginBottom="1rem"
          />
          <hr style={{ borderTop: 0, marginBottom: "1.5rem" }} />
        </>
      )}
      <Collapse in={data?.length > 0}>
        {allOpen ? (
          <Button
            icon={Icons.arrowUp}
            onClick={() => switchAll(false)}
            color={"secondary"}
          >
            Close all
          </Button>
        ) : (
          <Button
            icon={Icons.arrowDown}
            onClick={() => switchAll(true)}
            color={"primary"}
          >
            Open all
          </Button>
        )}

        {data.map((item, index) => (
          <AvailableDataSource
            isOpen={switches[index]?.isOpen}
            index={index}
            key={`${item.source}-${index}`}
            dataSource={item}
            updateSwitch={(index) => updateSwitch(index)}
          />
        ))}
      </Collapse>
    </>
  )
}

export default function SourcesAvailable({ history }) {
  const [searchedSources, setSearchedSources] = useState({
    availableSources: [],
    isLoading: false,
  })

  const { search } = getQueryParams(["search"])

  useEffect(() => {
    ;(async () => {
      if (search) {
        const { ok, data, error } = await DataSourceAPI.searchSources({
          definition: search,
        })
        if (ok) {
          setSearchedSources({ availableSources: data.dataSources, isLoading: false })
        } else {
          toast.error(error)
          setSearchedSources({ availableSources: [], isLoading: false })
        }
      } else {
        setSearchedSources({ availableSources: [], isLoading: false })
      }
    })()
  }, [search])

  // render navbar and footer
  useLayoutOpts(true, true)

  // fetch available definitions
  const { definitions, loading } = useDefinitions()
  const selectedDefinition = definitions[search]
  const definitionOptions = Object.entries(definitions)
    .map(([defName, def]) => ({
      value: defName,
      option: {
        title: def.summary,
        subtitle: def.definition,
        description: def.description,
        label: def.version ? `v${def.version}` : null,
      },
    }))
    .filter((option) => !option.value.startsWith("test/"))

  async function onSelectDefinition(event) {
    const definition = event.target.value
    if (definitions[definition]) {
      const target = new URL(routes.SOURCES_AVAILABLE, window.location.origin)
      target.searchParams.set("search", definition)
      history.push(target.pathname + target.search)
    }
  }

  return (
    <MainContentBox>
      <MetaData {...labels.meta.availableSources} />
      <Grid container columnSpacing={"3rem"}>
        <Grid container item sm={12} md={12} lg={8}>
          <Header
            breadCrumb={
              <Breadcrumbs
                current={BreadCrumbsNames.AVAILABLE_SOURCES}
                paths={[BreadCrumbsPath.DEVELOPER_PORTAL]}
              />
            }
            title="Available data sources"
          >
            <Text csx={{ mb: 1 }}>
              Select one of the available definitions to see any published data sources
              for it and get the necessary details to take it into use.
            </Text>
            <p>
              To make your own data available here, register a data source on the{" "}
              <InlineLink href={routes.MY_SOURCES}> my data sources</InlineLink> page,
              and publish it.{" "}
              {!config.disableApps && (
                <>
                  Some sources may require authentication, and to get the authentication
                  token you need to register your application on the{" "}
                  <InlineLink href={routes.APPLICATIONS}>my applications</InlineLink>{" "}
                  page, and integrate it to the authentication system.
                </>
              )}
              {config.disableApps && (
                <>
                  Some sources may require authentication, and to get the authentication
                  token you need to integrate your application to the authentication
                  system.
                </>
              )}
            </p>
          </Header>
        </Grid>
        <ResourcesGrid item sm={12} md={12} lg={4}>
          {dsConfig.components["Data Definitions"] && (
            <ResourcesBox
              links={[
                {
                  text: " Learn more about the data definitions",
                  link: dsConfig.components["Data Definitions"],
                },
              ]}
            />
          )}
        </ResourcesGrid>
        <Grid item xs={12} md={12} lg={8}>
          <hr style={{ borderTop: 0 }} />
          {loading ? (
            <InitialLoading />
          ) : (
            <SelectWrapper as="form">
              <Select
                baseProps={{
                  "data-cy": "definition",
                }}
                placeholder={"Select data definition"}
                value={search || ""}
                name="definition"
                id="definition"
                label=""
                options={definitionOptions}
                handleChange={onSelectDefinition}
                renderSelectedTitle
                description={
                  "Choose a data product to see published sources implementing it. You can submit PRs to propose new definitions at"
                }
                descriptionLink={config.sourcesRepo}
              />
            </SelectWrapper>
          )}
          <Box>
            {searchedSources.isLoading ? (
              <InitialLoading />
            ) : (
              <SearchResult
                selectedDefinition={selectedDefinition}
                data={searchedSources.availableSources}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </MainContentBox>
  )
}

const DataSourcesText = styled(Typography)`
  font-style: italic;
  margin-bottom: ${(p) => p.theme.spacing(1.5)};
  color: ${(p) => p.theme.palette.text.disabled};
`

const SelectWrapper = styled(Box)`
  margin-top: ${(p) => p.theme.spacing(1.5)};
  margin-bottom: ${(p) => p.theme.spacing(1.5)};

  // remove extra spacing above the Select
  .MuiFormControl-root .MuiBox-root {
    margin: 0;
  }
`

const ResourcesGrid = styled(Grid)`
  margin-bottom: 1.5rem;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: 7.2rem;
  }
`
