import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import { LinkButton, Submit } from "@ioxio-priv/dataspace-ui"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import { styled } from "@mui/material/styles"
import Joi from "joi"

import AdvancedFieldControl from "./AdvancedFieldControl"
import FormInput from "./FormInput"
import { FormBody, HorizontalLine } from "../commonStyles"
import Form from "../components/Form"
import { labels } from "../constants/labels"
import { Icons } from "../dsIcon"
import { setEmptyStringsToNull } from "../utilities"
import { toastError } from "../utilities/errors"
import { ValidationRules } from "../utilities/formValidationUtil"

export function CreateGroupForm({ asyncOnSubmit = async () => ({ ok: true }) }) {
  const [isOpen, setIsOpen] = useState(false)
  const schema = Joi.object({
    group: ValidationRules.group,
    partyConfigurationDomain: ValidationRules.partyConfigurationDomain,
    gdprContact: ValidationRules.gdprContact,
  })
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(schema),
  })

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create a group", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (!isFormValid) {
      return { ok: false }
    }
    const transformedPayload = setEmptyStringsToNull(form.getValues())
    return asyncOnSubmit(transformedPayload, handleBackendErrors)
  }

  return (
    <Form
      onSubmit={(e) => e.preventDefault()}
      rightButtons={
        <>
          <LinkButton variant={"outlined"} icon={Icons.cancel} href={`/groups`}>
            Cancel
          </LinkButton>
          <Submit
            icon={Icons.success}
            color={"success"}
            asyncOnClick={{ asyncFn: _asyncOnSubmit, loadingText: "Reserving..." }}
          >
            Create
          </Submit>
        </>
      }
    >
      <FormBody>
        <FormInput
          tooltipText={labels.group.fields.name.tooltipText}
          required
          name={"group"}
          placeholder={"Unique name for the group"}
          label={labels.group.fields.name.label}
          form={form}
        />
        <FormInput
          tooltipText={labels.group.fields.partyConfigurationDomain.tooltipText}
          name={"partyConfigurationDomain"}
          label={labels.group.fields.partyConfigurationDomain.label}
          placeholder={"example.com"}
          form={form}
        />
      </FormBody>
      <HorizontalLine />
      <AdvancedFieldControl isOpen={isOpen} setIsOpen={setIsOpen} />
      <Collapse in={isOpen}>
        <InputBox>
          <FormInput
            name={"gdprContact"}
            label={labels.group.fields.gdprContact.label}
            placeholder={"Email"}
            tooltipText={labels.group.fields.gdprContact.tooltipText}
            form={form}
          />
        </InputBox>
      </Collapse>
    </Form>
  )
}

const InputBox = styled(Box)`
  padding: 0 1rem 1rem;
`
