import * as React from "react"

import { LinkBox, styled } from "@ioxio-priv/dataspace-ui"
import { Grid, Typography } from "@mui/material"
import Box from "@mui/material/Box"

export default function ResourcesBox({ title = "Resources", links }) {
  const Wrapper = styled(Box)({
    marginBottom: "1.5rem",
  })

  return (
    <Wrapper data-cy={"resources"}>
      <Typography variant="subtitle1" mb={"1rem"}>
        {title}:
      </Typography>
      <Grid display="flex" flexDirection="column" gap="1rem">
        {links.map(({ text, link }) => (
          <LinkBox target={"_blank"} key={link} href={link}>
            {text}
          </LinkBox>
        ))}
      </Grid>
    </Wrapper>
  )
}
