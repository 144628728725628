import { toast } from "react-toastify"

export function isActivationKey(event) {
  // Space or Enter
  return event.keyCode === 32 || event.keyCode === 13
}

export function getPublicUrl() {
  /* eslint-disable no-undef */
  if (
    typeof process !== "undefined" &&
    process.env &&
    typeof process.env.PUBLIC_URL === "string"
  ) {
    return process.env.PUBLIC_URL
  } else {
    return "/"
  }
  /* eslint-enable no-undef */
}

export async function getDelay(ms = 1000) {
  return new Promise((r) => setTimeout(r, ms))
}

export function getUrlParams(names) {
  const params = new URLSearchParams(document.location.search.substring(1))
  const data = {}
  for (const name of names) {
    data[name] = params.get(name)
  }
  return data
}

export function getQueryParams(params) {
  const query = new URLSearchParams(document.location.search)
  const queryParams = {}
  params.map((item) => (queryParams[item] = query.get(item)))
  return queryParams
}

export function stripProtocolPrefix(url) {
  const prefixes = ["https://", "http://"]
  prefixes.forEach((prefix) => {
    if (url.startsWith(prefix)) {
      url = url.slice(prefix.length)
    }
  })
  return url
}

export function getRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}

export function convertDateUI(dateString, format = "yyyy-mm-dd") {
  if (!dateString) {
    return "N/A"
  }

  const date = new Date(dateString)
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0]
}

export function makeRange(n) {
  return [...Array(n).keys()]
}

export async function copyToClipboard(text, successText) {
  await navigator.clipboard.writeText(text)
  toast.success(successText)
}

export function setEmptyStringsToNull(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === "") {
      data[key] = null
    }
  })
  return data
}

export function interactionOnClickHandlers(onClick) {
  return {
    onClick: onClick,
    onKeyUp: function _onKeyUp(evt) {
      if (isActivationKey(evt)) {
        onClick()
      }
    },
  }
}
