import React from "react"
import { Controller } from "react-hook-form"

import { Checkbox } from "@ioxio-priv/dataspace-ui"

export default function FormCheckbox({
  name,
  label,
  form,
  checkboxSx = {},
  children,
  tooltipText,
}) {
  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={false}
      render={({ field }) => (
        <Checkbox
          label={label}
          name={name}
          checked={field.value}
          handleChange={field.onChange}
          baseProps={{ sx: checkboxSx, ...field }}
          tooltipText={tooltipText}
        >
          {children}
        </Checkbox>
      )}
    />
  )
}
