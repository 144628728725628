import API from "./API"

class DataSourceAPI extends API {
  async create(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async deleteSource(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources`, {
      method: "DELETE",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async getDataSource(definition, source) {
    return await this.makeRequest(
      `${this.baseUrl}/v1/sources/${definition}/${source}`,
      {
        method: "GET",
        headers: this.headers,
      }
    )
  }

  async getMySources() {
    return await this.makeRequest(`${this.baseUrl}/v1/sources/my-sources`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async getDataSourceDefinitions() {
    return await this.makeRequest(`${this.baseUrl}/v3/data-source-definitions`, {
      method: "GET",
      headers: this.headers,
    })
  }

  async searchSources(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources/search`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async updateSource(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources`, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }

  async refreshPreviewToken(data) {
    return await this.makeRequest(`${this.baseUrl}/v1/sources/refresh-preview-token`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(data),
    })
  }
}

const instance = new DataSourceAPI()

export default instance
