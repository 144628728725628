import React from "react"

import { Breadcrumbs } from "@ioxio-priv/dataspace-ui"
import Grid from "@mui/material/Grid"

import { MainContentBox } from "@/commonStyles"
import Header from "@/components/Header"
import { MetaData } from "@/components/MetaData"
import ResourcesBox from "@/components/ResourcesBox"
import { SourcesHelp } from "@/components/SourcesHelp"
import { BreadCrumbsPath } from "@/constants/breadcrumbs"
import useLayoutOpts from "@/hooks/useLayoutOpts"
import { config } from "@/settings"

import "react-toastify/dist/ReactToastify.css"

export default function SourcesWrapper({
  children,
  belowHeader,
  breadCrumbs,
  title,
  meta,
  editPage = false,
}) {
  // render navbar and footer
  useLayoutOpts(true, true)

  const resources = (
    <ResourcesBox
      links={[
        {
          text: "Learn more about data sources and how to build one on the dataspace",
          link: config.guideDataSources,
        },
        {
          text: "Example implementation of a productizer",
          link: config.exampleProductizer,
        },
        {
          text: "Watch data productization tutorial on YouTube",
          link: "https://www.youtube.com/watch?v=f-f6P_-8zoQ",
        },
        {
          text: "Guides for creating data sources",
          link: "https://ioxio.com/guides/how-to-build-a-data-source",
        },
      ]}
    />
  )

  return (
    <MainContentBox>
      <MetaData {...meta} />
      <Grid container columnSpacing={"1.5rem"}>
        <Grid item sm={12} md={12} lg={8}>
          <Header
            title={title}
            breadCrumb={
              <Breadcrumbs
                current={breadCrumbs}
                paths={[BreadCrumbsPath.DEVELOPER_PORTAL, BreadCrumbsPath.SOURCES]}
              />
            }
          >
            {!editPage && <SourcesHelp />}
          </Header>
          {belowHeader}
          <Grid item display={{ lg: "none" }}>
            {resources}
          </Grid>
          {children}
        </Grid>
        <Grid item lg={4} mt="7.2rem" display={{ xs: "none", lg: "block" }}>
          {resources}
        </Grid>
      </Grid>
    </MainContentBox>
  )
}
