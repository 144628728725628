import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi/dist/joi"
import { FormDialog } from "@ioxio-priv/dataspace-ui"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import Joi from "joi"

import InputWithDropdownForm from "@/components/InputWithDropdownForm"

export default function InviteFormDialog({
  asyncOnSubmit,
  isOpen,
  onClose,
  icon,
  email,
  isUpdateRole,
  role = "Member",
  title = "Invite member to group",
  description,
  confirmText = "Invite",
  inviteError,
}) {
  const SCHEMA = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    role: Joi.string().required(),
  })

  if (role) {
    role = role.replace(/\b\w/g, (match) => match.toUpperCase())
  }

  const options = ["Owner", "Member"]

  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(SCHEMA),
    defaultValues: {
      email: email,
      role: role,
    },
  })

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()

    if (!isFormValid) {
      return {
        ok: false,
      }
    }

    const data = form.getValues()
    return asyncOnSubmit(data)
  }

  return (
    <FormDialog
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      asyncOnClick={{ asyncFn: _asyncOnSubmit }}
      confirmText={confirmText}
      submitButtonProps={{
        icon: icon,
        color: "success",
        baseProps: {
          "data-cy": "confirmation-btn",
        },
      }}
    >
      <>
        {description && <Description>{description}</Description>}
        <InputWithDropdownForm
          inputName={"email"}
          inputPlaceholder={"Email"}
          inputType={"email"}
          selectName={"role"}
          selectPlaceholder={"Role"}
          wrapperSx={{ mb: "3.5rem" }}
          form={form}
          disabledInputField={isUpdateRole}
          inputRequired={true}
          selectOptions={options}
          selectRequired={true}
          inviteError={inviteError}
          showInviteOnlyInfo={true}
        />
      </>
    </FormDialog>
  )
}

const Description = styled(Typography)`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(p) => p.theme.palette.primary.main};
`
