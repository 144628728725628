import React from "react"

import { Button, IconButton, InputField, styled } from "@ioxio-priv/dataspace-ui"
import Grid from "@mui/material/Grid"

import Text from "@/components/Text"
import { labels } from "@/constants/labels"
import { Icons } from "@/dsIcon"
import { config } from "@/settings"
import { copyToClipboard } from "@/utilities"

function createUrl(definition, source) {
  return `${config.productGateway}/${definition}?source=${source}`
}

function DataSourceHeader({ source, definition, index, isOpen, updateSwitch }) {
  const DataSourceHeaderGrid = styled(Grid)`
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    ${(p) => p.isOpen || `background: #EEEFEC`};
    padding: 1rem;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid #eeefec;
  `

  return (
    <DataSourceHeaderGrid
      data-testid={`${definition}-${source}`}
      container
      item
      onClick={() => updateSwitch(index)}
      isOpen={isOpen}
    >
      <DataSourceHeaderGridItem item container direction="column" xs={8}>
        <Wrapper>
          <Text
            baseProps={{ "data-cy": source }}
            csx={{ fontWeight: "600", fontSize: "1.125rem" }}
          >
            {source}
          </Text>
        </Wrapper>
      </DataSourceHeaderGridItem>
      <DataSourceHeaderGridItem item>
        {isOpen ? (
          <Button
            baseProps={{ "data-cy": `close-${source}` }}
            icon={Icons.arrowUp}
            onClick={() => updateSwitch(index)}
            color={"secondary"}
          >
            Close
          </Button>
        ) : (
          <Button
            baseProps={{ "data-cy": `open-${source}` }}
            icon={Icons.arrowDown}
            onClick={() => updateSwitch(index)}
            color={"primary"}
          >
            Open
          </Button>
        )}
      </DataSourceHeaderGridItem>
    </DataSourceHeaderGrid>
  )
}

function DataSourceBody({ dataSource }) {
  const { definition, gdprContact, partyConfigurationDomain, source } = dataSource
  const requestURL = createUrl(definition, source)

  return (
    <DataSourceBodyGrid container item direction={"column"}>
      <DataSourceBodyField
        dataCy={`data-product-${definition}`}
        value={definition}
        label={labels.availableSources.fields.dataProduct.name}
      />
      <DataSourceBodyField
        dataCy={`data-source-name-${source}`}
        value={source}
        label={labels.availableSources.fields.dataSourceName.name}
      />
      <DataSourceBodyField
        dataCy={`request-url-${source}`}
        value={requestURL}
        label={labels.availableSources.fields.requestUrl.name}
      />
      <DataSourceBodyField
        dataCy={`dsi-${source}`}
        value={`dpp://${source}@${config.dataspaceDomain}/${definition}`}
        label={labels.availableSources.fields.dataSourceIdentifier.name}
      />
      {gdprContact && (
        <DataSourceBodyField
          dataCy={`gdpr-contact-${source}`}
          value={gdprContact}
          label={labels.availableSources.fields.gdprContact.name}
        />
      )}
      {partyConfigurationDomain && (
        <DataSourceBodyField
          dataCy={`party-configuration-domain-${source}`}
          value={partyConfigurationDomain}
          label={labels.availableSources.fields.partyConfiguration.name}
        />
      )}
    </DataSourceBodyGrid>
  )
}

function DataSourceBodyField({ dataCy, value, label }) {
  return (
    <InputField
      baseProps={{
        tabIndex: -1,
        "data-cy": dataCy,
      }}
      readonly={true}
      name={label}
      label={label}
      value={value}
    >
      <IconButton
        color={"secondary"}
        icon={Icons.copy}
        title={`Copy ${label}`}
        onClick={() =>
          copyToClipboard(value, `${label} has been copied to the clipboard`)
        }
      />
    </InputField>
  )
}

export default function AvailableDataSource({
  dataSource,
  index,
  isOpen,
  updateSwitch,
}) {
  return (
    <MainGrid data-cy={"source-item"}>
      <DataSourceHeader
        source={dataSource.source}
        definition={dataSource.definition}
        isOpen={isOpen}
        index={index}
        updateSwitch={updateSwitch}
      />
      {isOpen && <DataSourceBody dataSource={dataSource} />}
    </MainGrid>
  )
}

const DataSourceHeaderGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  max-width: 85%;
  text-overflow: ellipsis;
`

const DataSourceBodyGrid = styled(Grid)`
  flex-direction: column;
  justify-content: normal;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem;
  gap: 1rem;
  border-radius: 0 0 10px 10px;
`

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const MainGrid = styled(Grid)`
  margin: 1.5rem 0;
  border: 1px solid ${(p) => p.theme.palette.neutral.main};
  border-radius: 0.3125rem;
  overflow: hidden;
`
