import React from "react"

import { IconButton } from "@ioxio-priv/dataspace-ui"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"

import { Icons } from "../dsIcon"

export default function AdvancedFieldControl({ isOpen, setIsOpen }) {
  return (
    <Control>
      <Typography variant="subtitle1">Advanced</Typography>
      {isOpen ? (
        <IconButton
          icon={Icons.arrowUp}
          onClick={() => setIsOpen(false)}
          variant="outlined"
          color="secondary"
        />
      ) : (
        <IconButton
          icon={Icons.arrowDown}
          onClick={() => setIsOpen(true)}
          variant="outlined"
          color="secondary"
        />
      )}
    </Control>
  )
}

const Control = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`
