import { baseConfig, baseDsConfig } from "./base"

// Dataspace specific configuration
export const dsConfig = {
  ...baseDsConfig,
}

// Application specific configuration
export const config = {
  ...baseConfig,
}
